import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { serviciosItems } from "./assets/data/servicios-items";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      // navbar
      logoHeader: "Interior design studio",
      nosotros: "about",
      servicios: "services",
      proyectos: "projects",
      contacto: "contact",

      //   floating btns
      whatsapp: "Write us on WhatsApp",
      backTT: "Back to top",

      // hero
      header: "interior designer",
      subheader: "make your ideas come true",

      // about
      aboutText:
        "I am <1>Cecilia D'Atri</1>, Interior Designer and passionate about decoration, order, aesthetics and visual harmony. <br/><br/> I enjoy listening to the needs of each of my clients, getting to know them, capturing their personality and their preferences in order to be able to get it right when it comes to advising them. Creating a bond with them makes everything flow and they feel happy when the project is finished. Seeing that moment is extremely gratifying. <br/><br/> I work together with professionals from all areas to provide a complete service. Whether they are in renovation, construction, architects, painters, electricians, plumbers, but also brand designers and graphic designers.",

      // services
      interiorismo: "interior design",
      intText:
        "If you want to <span>reform</span> but don't know where to start, I'll help you find the best option based on your needs, <span>make a plan</span> of the existing and renovated house or business. We also work with <span>3D images</span> so you can see the final result.",

      decoracion: "decoration",
      decText:
        "The <span>decorative elements</span> are what bring the project to life. <span>I advise you</span> in choosing the colors, furniture, layout and elements for the style and image you want to create in your space. I can also help you <span>re-organize</span> furniture and spaces.",

      persoShopper: "personal shopper",
      psText:
        "I <span>accompany you</span> in the <span>purchase</span> process of the elements that comprise the developed project, or <span>we carry out the purchase, transfer and assembly</span> for you so you don't go through that moment of stress and arrive at your new <span>home or business</span> completely tailored to your needs.",

      personalizados: "personalized",
      persoText:
        "If there is any service not mentioned previously, do not hesitate to ask me about it.",

      // projects
      projectText:
        "To carry out the design, selection and execution of projects, I take into account the personal preferences of each client, preferred color palette, style, habits and use of the environment in question. <br/><br/> Taking these points, I am inspired by the trends, the materials and the location of each element in the space to achieve the perfect setting.",

      // contact
      contactDesc:
        "If you're still struggling with your home decoration, <1>send me a message</1> and I'll assist you",
      contactFormTitle: "what is your question?",
      name: "name*",
      namePlaceholder: "Your name",
      telefono: "phone number",
      emailPlaceholder: "email@example.com",
      deja: "let me know your needs*",
      dejaPlaceholder: "Hello Ceci ! ...",
      enviar: "send",
      sending: "sending...",
      inputValueMissing: "Please fill all required input",
      graciasEnviar:
        "Thank you for getting in touch ! <br /><br /> We'll get back to you as soon as possible",
    },
  },
  es: {
    translation: {
      // navbar
      logoHeader: "Estudio de Interiorismo",
      nosotros: "nosotros",
      servicios: "servicios",
      proyectos: "proyectos",
      contacto: "contacto",

      //   floating btns
      whatsapp: "Escríbenos por WhatsApp",
      backTT: "Volver arriba",

      // hero
      header: "diseño de interiores",
      subheader: "haz realidad tus ideas",

      // about
      aboutText:
        "Soy <1>Cecilia D'Atri</1>, Interiorista y apasionada por la decoración, el orden, la estética y la armonía visual. <br/><br/> Disfruto escuchar la necesidad de cada uno de mis clientes, conocerlos, captar su personalidad y sus preferencias para poder acertar a la hora de asesorarlos. Crear un vínculo con ellos hace que todo fluya y se sientan felices al terminar el proyecto. Ver ese momento es sumamente gratificante. <br/><br/> Trabajo en conjunto con profesionales de todos los rubros para poder brindar un servicio completo. Ya sean de reforma, construcción, arquitectos, pintores, electricistas, fontaneros, diseñadores de marcas comerciales y diseñadores gráficos.",

      // services
      interiorismo: serviciosItems[0].title,
      intText: serviciosItems[0].text,

      decoracion: serviciosItems[1].title,
      decText: serviciosItems[1].text,

      persoShopper: serviciosItems[2].title,
      psText: serviciosItems[2].text,

      personalizados: serviciosItems[3].title,
      persoText: serviciosItems[3].text,

      // projects
      projectText:
        "Para llevar a cabo el diseño, selección y ejecución de los proyectos, tengo en cuenta las preferencias personales de cada cliente, paleta de colores preferida, estilo, hábitos y uso del ambiente en cuestión. <br/><br/> Tomando estos puntos, me inspiro en las tendencias, los materiales y ubicación de cada elemento en el espacio para lograr la ambientación perfecta.",

      // contact
      contactDesc: "Si aún estás con dudas... <1>escribeme</1> que te ayudo.",
      contactFormTitle: "¿Cuál es tu consulta?",
      name: "nombre*",
      namePlaceholder: "Su nombre",
      telefono: "teléfono",
      emailPlaceholder: "correo@ejemplo.com",
      deja: "déjame tu consulta*",
      dejaPlaceholder: "Hola Ceci! ...",
      enviar: "enviar",
      sending: "enviando...",
      inputValueMissing: "Por favor, complete los campos requeridos.",
      graciasEnviar:
        "¡Gracias por tu mensaje! <br /><br /> Nos pondremos en contacto contigo lo antes posible.",
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "es",
    resources,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
